import ContactContent from '../components/contact/ContactContent';
import ContactHead from "../components/contact/ContactHead";

export default function Contact() {
  return (
    <>
      <ContactHead />
      <ContactContent />
    </>
  );
}