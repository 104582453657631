import HomeContent from '../components/home/HomeContent';
import HomeHead from "../components/home/HomeHead";

export default function HomeFR() {
  return (
    <>
      <HomeHead />
      <HomeContent />
    </>
  );
}