import FrenchPage from '../components/lifecycle/FrenchPage';
import InsideHouse from './InsideHouse'

export default function InsideHouseFR() {
  return (
    <>
      <FrenchPage />
      <InsideHouse />
    </>
  );
}